<template>
  <div class="overflow-x-hidden leading-relative">
    <Highlight />
    <Header />
    <main class="relative z-10">
      <Nuxt />
    </main>
    <LazyHydrate when-visible>
      <Footer />
    </LazyHydrate>
    <noscript v-html="cheqTag.noScript" />
  </div>
</template>

<script>
import Header from '~/components/header/Header.vue';
import Footer from '~/components/footer/Footer.vue';
import Highlight from '~/components/Highlight.vue';
import LazyHydrate from 'vue-lazy-hydration';

export default {
  head() {
    const script = [];

    if (this.language.extra_gtm_key) {
      const attrs = {
        hid: 'ExtraGTM',
        src: `https://www.googletagmanager.com/gtag/js?id=${this.language.extra_gtm_key}`,
        async: true,
      };
      script.push(attrs);
    }

    if (this.cheqTag) {
      script.push(this.cheqTag.script);
    }

    return {
      script,
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    cheqTag() {
      return this.$cheq(this.language.domain);
    },
  },
  components: {
    LazyHydrate,
    Highlight,
    Header,
    Footer,
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

* {
  -webkit-font-smoothing: antialiased;
}

.skew {
  transform: skew(0deg, -1deg);
}

.unskew {
  transform: skew(0deg, 1deg);
}

.skew,
.unskew {
  backface-visibility: hidden;
}

.caret {
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
</style>
