<template>
  <div
    v-click-outside="hide"
    class="dropdown-wrapper sm:inline-flex w-full sm:w-auto items-center relative border-white border-opacity-20 sm:border-b-0 z-40"
  >
    <a
      href="javascript:;"
      role="button"
      class="flex text-black sm:text-white sm:hover:text-white hover:no-underline w-full sm:inline-flex items-center sm:border-none border-b border-gray-100 px-6 py-6 sm:py-0 focus:outline-none sm:bg-transparent bg-silver-100"
      aria-haspopup="true"
      :aria-expanded="isExpanded.toString()"
      @click="toggle"
    >
      <span class="w-full sm:w-auto">
        <slot name="button" />
      </span>
    </a>
    <div
      v-if="isExpanded"
      role="menu"
      class="static top-full sm:absolute sm:bg-white text-gray-600 sm:text-black right-0 min-w-min sm:shadow-dropdown border-1 border-black border-opacity-20 rounded overflow-hidden z-10 px-0 sm:pl-0"
    >
      <span ref="content">
        <slot name="content" />
      </span>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  directives: {
    clickOutside: ClickOutside,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },

  destroyed() {
    document.removeEventListener('click', this.handleOutsideClick);
  },

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
      this.$emit('toggled', this.isExpanded);
    },
    hide() {
      this.isExpanded = false;
    },
  },
};
</script>
