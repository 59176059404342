<template>
  <div v-if="lang" class="language-flag shrink-0 px-0.5 py-0 rounded bg-white mr-2 inline-block">
    <img
      class="flag w-5 rounded"
      :alt="lang"
      :loading="loading"
      :src="`https://assets.parkos.com/assets/img/flags/${language}.png`"
      width="20px"
      height="20px"
    />
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      default: () => null,
    },
    loading: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    language() {
      if (this.lang.includes('be')) return 'be';
      return this.lang;
    },
  },
};
</script>

<style lang="scss">
.language-flag {
  min-width: 20px;
}
</style>
