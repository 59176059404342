<template>
  <div class="flex flex-row">
    <a target="_blank" href="https://www.facebook.com/ParkosInternational/">
      <IconFacebook class="w-6 h-6 text-white fill-current" />
    </a>
    <a target="_blank" href="https://www.youtube.com/c/ParkosInternational/">
      <IconYoutube class="w-6 h-6 text-white fill-current ml-3" />
    </a>
    <a target="_blank" href="https://twitter.com/ParkosInt/">
      <IconTwitter class="w-6 h-6 text-white fill-current ml-3" />
    </a>
    <a href="https://www.instagram.com/parkos.international/" target="_blank">
      <IconInstagram class="w-6 h-6 text-white fill-current ml-3" />
    </a>
    <a href="https://www.pinterest.com/parkosinternational/" target="_blank">
      <IconPinterest class="w-6 h-6 text-white fill-current ml-3" />
    </a>
  </div>
</template>

<script>
import IconTwitter from '~/components/icons/IconTwitter.vue';
import IconYoutube from '~/components/icons/IconYoutube.vue';
import IconFacebook from '~/components/icons/IconFacebook.vue';
import IconInstagram from '~/components/icons/IconInstagram.vue';
import IconPinterest from '~/components/icons/IconPinterest.vue';

export default {
  components: {
    IconTwitter,
    IconYoutube,
    IconFacebook,
    IconInstagram,
    IconPinterest,
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
    twitter() {
      return this.language.socials.twitter;
    },
    facebook() {
      return this.language.socials.facebook;
    },
    youtube() {
      return this.language.socials.youtube;
    },
  },
};
</script>

<style></style>
