<template>
  <Modal v-model="show">
    <template #header>
      <h3 class="font-sans font-bold text-lg leading-sung mb-0">
        {{ $i18n('general.opening-hours') }}
      </h3>
    </template>
    <template #body>
      <div class="pt-5 pb-1">
        <ul class="mb-5">
          <li
            v-for="({ dayOfWeek, startTime, endTime }, index) in openingTimes.openingHours"
            :key="index"
            class="flex justify-between mb-2.5"
          >
            <div class="leading-sung">
              {{ getWeekDays[dayOfWeek] }}
            </div>
            <div class="leading-sung">{{ startTime }} &ndash; {{ endTime }}</div>
          </li>
        </ul>
        <div class="text-manatee text-md leading-5">
          {{ $i18n('general.opening-hours-except-holiday') }}
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from './support/Modal/Modal.vue';

export default {
  name: 'OpeningTime',
  components: {
    Modal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    show: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      },
    },

    openingTimes() {
      const { openingTimes, language } = this.$store.state;
      return openingTimes[language.lang];
    },

    /**
     * Get weekdays based on the browsers' translations
     * Sunday = 0, Monday = 1
     */
    getWeekDays() {
      const { language } = this.$store.state;
      const getWeekDays = (locale) =>
        [...Array(7).keys()].map((i) =>
          new Date(Date.UTC(1970, 0, 4 + i)).toLocaleDateString(locale, {
            weekday: 'long',
          }),
        );

      return getWeekDays(language.lang);
    },
  },
};
</script>

<style scoped></style>
