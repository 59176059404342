<template>
  <div v-if="navigation && navigation.length" class="breadcrumbs py-10px sm:py-5 font-sans inline-flex gap-3">
    <div
      v-for="(navItem, index) in navigation"
      :key="navItem.href"
      class="breadcrumbs__nav flex items-center gap-2 hover:text-blue-600"
      :class="{
        'text-manatee': index < navigation.length - 1,
        'text-black': navigation.length === 1 || navigation.length - 1 === index,
      }"
    >
      <a :href="navItem.href" class="sm:text-base text-md text-current hover:no-underline">{{ navItem.title }}</a>
      <CaretRight v-if="index !== navigation.length - 1" class="text-manatee" />
    </div>
  </div>
</template>

<script>
import CaretRight from '~/components/icons/IconCaretRight.vue';

export default {
  components: {
    CaretRight,
  },
  props: {
    navigation: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.breadcrumbs {
  &__nav {
  }
}
</style>
