<template>
  <div>
    <div class="bg-white">
      <div class="xs:container px-5 sm:px-10 overflow-auto whitespace-nowrap breadcrumbs-container font-sans">
        <LazyHydrate never>
          <BreadCrumbs :navigation="navigation" />
        </LazyHydrate>
      </div>
      <div class="topics_faq lg:container lg:flex lg:mb-20">
        <div class="xs:container px-5 sm:px-10 lg:pr-6 lg:min-w-[450px] lg:w-[55%]">
          <LazyHydrate when-idle>
            <Topics class="pt-10 md:mt-20 lg:mt-10 lg:p-[30px] lg:border rounded lg:border-[#e5e7eb]" />
          </LazyHydrate>
        </div>
        <div class="xs:container px-5 sm:px-10">
          <LazyHydrate when-idle>
            <Faq class="py-10 md:py-20 lg:p-4 lg:pt-10 font-sans" />
          </LazyHydrate>
        </div>
      </div>
    </div>
    <div class="bg-silver-100">
      <div class="xs:container px-5 sm:px-10 bg-silver-100 lg:flex gap-x-12">
        <LazyHydrate when-idle>
          <SupportV2 />
        </LazyHydrate>
        <LazyHydrate when-idle>
          <HelpTeam class="w-5/12" />
        </LazyHydrate>
        <LazyHydrate when-idle>
          <ParkosTeam class="pt-10 md:pt-20 flex justify-between lg:hidden" />
        </LazyHydrate>
      </div>
      <LazyHydrate when-idle>
        <HelpButton
          v-if="['nl-be', 'nl', 'de', 'de-at', 'es', 'it', 'fr-be', 'fr', 'sv-se'].includes(language.lang)"
          :language="language.lang"
        />
      </LazyHydrate>
      <client-only>
        <noscript>
          <iframe
            :src="`https://www.googletagmanager.com/ns.html?id=${gtmKey}&noscript=`"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
          ></iframe>
        </noscript>
      </client-only>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import BreadCrumbs from '~/components/BreadCrumbs.vue';
import HelpButton from '~/components/airport/HelpButton.vue';
import Faq from '~/components/faq/Faq.vue';
import Topics from '~/components//faq/Topics.vue';
import ParkosTeam from '~/components/support/parkosTeam.vue';
import HelpTeam from '~/components/support/helpTeam.vue';
import SupportV2 from '~/components/support/Supportv2.vue';

export default {
  components: {
    LazyHydrate,
    HelpButton,
    BreadCrumbs,
    Faq,
    Topics,
    ParkosTeam,
    HelpTeam,
    SupportV2,
  },
  layout: 'search',
  data() {
    return {
      faqSlugs: [
        {
          path: '/veel-gestelde-vragen/',
          slug: 'veel-gestelde-vragen',
          key: 'veel-gestelde-vragen',
          language: 'nl',
        },
        {
          path: '/veelgestelde-vragen/',
          slug: 'veelgestelde-vragen',
          key: 'veelgestelde-vragen',
          language: 'nl-be',
        },
        {
          path: '/foire-aux-questions/',
          slug: 'foire-aux-questions',
          key: 'foire-aux-questions',
          language: 'fr',
        },
        {
          path: '/foire-aux-questions/',
          slug: 'foire-aux-questions',
          key: 'foire-aux-questions',
          language: 'fr-be',
        },
        {
          path: '/viel-gestellte-fragen/',
          slug: 'viel-gestellte-fragen',
          key: 'viel-gestellte-fragen',
          language: 'de',
        },
        {
          path: '/frequently-asked-questions/',
          slug: 'frequently-asked-questions',
          key: 'frequently-asked-questions',
          language: 'en-us',
        },
        {
          path: '/viel-gestellte-fragen/',
          slug: 'viel-gestellte-fragen',
          key: 'viel-gestellte-fragen',
          language: 'de-at',
        },
        {
          path: '/frequently-asked-questions/',
          slug: 'frequently-asked-questions',
          key: 'frequently-asked-questions',
          language: 'en-gb',
        },
        {
          path: '/preguntas-frecuentes/',
          slug: 'preguntas-frecuentes',
          key: 'preguntas-frecuentes',
          language: 'es',
        },
        {
          path: '/domande-frequenti/',
          slug: 'domande-frequenti',
          key: 'domande-frequenti',
          language: 'it',
        },
        {
          path: '/perguntas-frequentes/',
          slug: 'perguntas-frequentes',
          key: 'perguntas-frequentes',
          language: 'pt',
        },
        {
          path: '/ofte-stillede-sporgsmal/',
          slug: 'ofte-stillede-sporgsmal',
          key: 'ofte-stillede-sporgsmal',
          language: 'da-dk',
        },
        {
          path: '/vanliga-fragor-och-svar/',
          slug: 'vanliga-fragor-och-svar',
          key: 'vanliga-fragor-och-svar',
          language: 'sv-se',
        },
        {
          path: '/najczesciej-zadawane-pytania/',
          slug: 'najczesciej-zadawane-pytania',
          key: 'najczesciej-zadawane-pytania',
          language: 'pl',
        },
        {
          path: '/frequently-asked-questions/',
          slug: 'frequently-asked-questions',
          key: 'frequently-asked-questions',
          language: 'en-au',
        },
        {
          path: '/frequently-asked-questions/',
          slug: 'frequently-asked-questions',
          key: 'frequently-asked-questions',
          language: 'en-eu',
        },
      ],
    };
  },
  async fetch() {
    await this.$store.dispatch('faq/getTopics');
  },
  head() {
    const links = [
      {
        rel: 'canonical',
        href: `https://${this.$paths.host}${this.$route.path}`,
      },
      {
        rel: 'alternate',
        hreflang: this.getHrefLang,
        href: `${this.$paths.url(false)}${this.$route.path}`,
      },
    ];
    const alternates = this.languages
      .filter((l) => l.id !== this.language.id && !l.is_hidden)
      .map((m) => ({
        rel: 'alternate',
        hreflang: m.lang === 'en-eu' ? 'en' : m.lang,
        href: (!m.domain.includes('https://') ? 'https://' : '') + m.domain + this.getFaqPath(m.lang),
      }));
    links.push(...alternates);

    const script = [];

    if (process.env.DATA_ANALYTICS_BUCKET === '1') {
      const bucketSourcePrefix = process.env.NODE_ENV === 'production' ? '/_nuxt_faq_page' : '';
      const bucketSource = `${bucketSourcePrefix}/da-bucket/dist/faq-page/index.js`;

      const bucketScript = {
        src: bucketSource,
        body: true,
      };

      script.push(bucketScript);
    }

    if (this.$paths.host === 'parkos.nl') {
      const attrs = {
        src: `https://www.googletagmanager.com/gtag/js?id=${this.$config.gtagTrackingId}`,
        async: true,
      };
      script.push(attrs);
    }

    return {
      title: this.faqContent.metatitle,
      htmlAttrs: {
        lang: this.getHrefLang,
      },
      meta: [
        {
          'http-equiv': 'content-language',
          content: this.getHrefLang,
        },
        {
          property: 'og:title',
          content: this.faqContent.metatitle,
        },
        {
          name: 'description',
          content: this.faqContent.metadescription,
        },
        {
          property: 'og:description',
          content: this.faqContent.metadescription,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: this.faqContent.metatitle,
        },
        {
          name: 'twitter:site',
          content: '@ParkosInt',
        },
        {
          name: 'twitter:creator',
          content: '@ParkosInt',
        },
        {
          name: 'twitter:description',
          content: this.faqContent.metadescription,
        },
        {
          name: 'twitter:image',
          content: `${this.$paths.assetsUrl}img/parkos_twitter_hero.png`,
        },
        {
          property: 'og:type',
          content: 'place',
        },
        {
          property: 'og:locale',
          content: this.getHrefLang,
        },
        {
          property: 'og:url',
          content: this.$paths.url(false) + this.$route.path,
        },
        {
          name: 'og:image',
          content: `${this.$paths.assetsUrl}img/parkos_twitter_hero.png`,
        },
      ],
      link: links,
      script,
      __dangerouslyDisableSanitizersByTagID: {
        datalayer: ['innerHTML'],
      },
    };
  },
  jsonld() {
    const articles = this.$store.state.faq.topics.flatMap((section) => section.articles);

    const mainEntity = articles.map((article) => ({
      '@type': 'Question',
      name: article.name,
      acceptedAnswer: {
        '@type': 'Answer',
        text: article.body,
      },
    }));

    return {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: mainEntity,
    };
  },
  computed: {
    faqStore() {
      return this.$store.state.faq;
    },
    gtmKey() {
      return this.language.gtm_key;
    },
    faqContent() {
      return this.$store.state.pageContent.faq || {};
    },
    navigation() {
      return [
        {
          href: '/',
          title: 'Home',
        },
        { href: '#', title: this.faqContent.title },
      ];
    },
    language() {
      return this.$store.state.language;
    },
    languages() {
      return this.$store.state.languages;
    },
    getHrefLang() {
      return this.language.lang === 'en-eu' ? 'en' : this.language.lang;
    },
  },
  mounted() {
    this.loadGTM();
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      // @ts-ignore
      cId: this.getCookie('cId'),
      // @ts-ignore
      domainName: this.$paths.host || '',
      pageType: 'home',
      token: '504Z8p2vH6TtWX7BJC0rYaArFl9sYKdAHfisTESx',
    });
    if (this.$paths.host === 'parkos.nl') {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }

      gtag('js', new Date());

      gtag('config', process.env.GTAG_TRACKING_ID);
    }
    this.setSessionCookies();

    if (this.$paths.host === 'parkos.de') {
      (function () {
        // eslint-disable-next-line no-underscore-dangle
        const _tsid = process.env.TS_ID;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-underscore-dangle
        const _tsConfig = {
          yOffset: '0',
          variant: 'reviews',
          customElementId: 'ts-badge',
          trustcardDirection: '',
          customBadgeWidth: '',
          customBadgeHeight: '',
          disableResponsive: 'false',
          disableTrustbadge: 'false',
        };
        // eslint-disable-next-line no-underscore-dangle
        const _ts = document.createElement('script');
        _ts.type = 'text/javascript';
        _ts.charset = 'utf-8';
        _ts.async = true;
        _ts.src = `//widgets.trustedshops.com/js/${_tsid}.js`;
        // eslint-disable-next-line no-underscore-dangle
        const __ts = document.getElementsByTagName('script')[0];
        __ts.parentNode.insertBefore(_ts, __ts);
      })();
    }
  },
  methods: {
    getFaqPath(language) {
      const slug = this.faqSlugs.find((slug) => slug.language === language);
      return slug ? slug.path : '/';
    },
    handleReferralCashbackCookie() {
      let cookie = this.getCookie('referral_cashback');
      if (cookie) {
        cookie = JSON.parse(decodeURIComponent(cookie));
      }
      this.$store.commit('referralCashback/set', cookie);
    },
    loadGTM() {
      const { gtmKey } = this;
      if (gtmKey) {
        /* eslint-disable */
        window.loadTagManager = function (w, d, s, l) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = `https://www.googletagmanager.com/gtm.js?id=${gtmKey}` + dl;
          f.parentNode.insertBefore(j, f);
        };
        if (typeof tagManagerDelay === 'undefined') {
          window.loadTagManager(window, document, 'script', 'dataLayer');
        }
        /* eslint-enable */
      }
    },
    setSessionCookies() {
      // Tries to set session cookies based on query params and after that retries pushing the cIdto the datalayer if its set in the cId cookie
      const params = this.$route.query;

      this.$axios({
        method: 'get',
        baseURL: '/',
        url: 'ajax/set-session-cookie',
        params,
      })
        .then(() => {
          window.dataLayer.push({
            cId: this.getCookie('cId'),
          });
        })
        .catch((error) => {
          // Add error handeling for failing session cookie links
          console.log(error);
        })
        .finally(() => {
          this.handleReferralCashbackCookie();
        });
    },
    getCookie(name) {
      const cookie = `; ${document.cookie}`;
      const cookieParts = cookie.split(`; ${name}=`);
      if (cookieParts.length === 2) {
        return cookieParts.pop().split(';').shift();
      }
      return '';
    },
    numberFormat(number) {
      return new Intl.NumberFormat(this.language.lang).format(number);
    },
  },
};
</script>

<style lang="scss">
.reviews-section {
  background-image: url('~/static/backgrounds/city.svg');
}

#trustbadge-container-98e3dadd90eb493088abdc5597a70810 {
  z-index: 39 !important;

  ._12n8yed {
    margin-bottom: 30px !important;
  }
}
</style>
