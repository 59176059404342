<template>
  <div class="font-sans">
    <!-- Search Results -->
    <div v-if="searchQuery && searchQuery.length" class="mb-5">
      <h2 class="text-nr md:text-xl font-bold mb-3">{{ $i18n('faq.faq-search-results-for') }}: {{ searchQuery }}</h2>
      <div v-if="searchResults && searchResults.length" class="grid gap-6">
        <div>
          <FaqCard
            v-for="searchResult in searchResults"
            :key="`${searchQuery}_${searchResult.id}`"
            :content="searchResult"
          />
        </div>
      </div>
      <p v-else class="text-gray-500 text-center my-5">
        {{ $i18n('faq.nothing-found') }}
      </p>
      <hr />
    </div>
    <!-- ./Search Results -->

    <!-- Contents -->
    <template v-if="selectedTopic">
      <div class="mb-3">
        <h2 class="text-xl md:text-[24px] font-cocogoose">{{ $i18n('faq.answers') }}; {{ selectedTopic.name }}</h2>
        <h2 class="leading-loose">{{ $i18n('faq.will-find-answer-here') }}</h2>
      </div>
      <div v-if="selectedTopic.articles && selectedTopic.articles.length" class="grid gap-6">
        <div class="border-b border-athens">
          <FaqCard
            v-for="content in selectedTopic.articles"
            :key="`${selectedTopic.id}_${content.id}`"
            :content="content"
          />
        </div>
      </div>
    </template>
    <!-- ./Contents -->
  </div>
</template>

<script>
import FaqCard from '~/components/faq/FaqCard.vue';

export default {
  components: {
    FaqCard,
  },
  computed: {
    faqState() {
      return this.$store.state.faq;
    },
    selectedTopic() {
      return this.faqState.selectedTopic;
    },
    searchQuery() {
      return this.faqState.searchQuery;
    },
    searchResults() {
      return this.faqState.searchResults;
    },
  },
};
</script>
