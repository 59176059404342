<template>
  <div
    class="search-form relative flex w-full select-none flex-col gap-3 rounded-md bg-white p-30px font-sans xs:p-5 sm:flex-row sm:flex-wrap md:z-20 md:flex-nowrap grid-cols-2 sm:grid md:flex max-w-6xl mt-7 md:p-0 md:justify-between"
  >
    <div class="sm:pr-4 md:pr-7 md:w-[81%] lg:w-full md:pl-5 flex justify-center items-center">
      <div class="pr-[10px] md:hidden">
        <svg
          width="20"
          height="20"
          version="1.1"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <g fill="none" fill-rule="evenodd">
            <g stroke="#9797a6" stroke-width="2">
              <g transform="translate(4 4)">
                <circle cx="7" cy="7" r="7" />
                <line x1="16" x2="12.464" y1="16" y2="12.464" stroke-linecap="round" />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <input
        id="search-input"
        name="searchquery"
        type="text"
        class="w-full h-full focus:outline-none"
        :placeholder="$i18n('faq.faq-search-placeholder')"
        @input="updateSearchQuery"
      />
    </div>
    <div class="self-center w-full md:w-12">
      <button
        class="cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap bg-orange-500 px-2 py-3 text-center font-bold text-white hover:bg-blaze-orange-500 w-full rounded-md md:rounded-r-md md:rounded-l-none"
        @click="onSearch"
      >
        <span class="hidden md:flex md:justify-center">
          <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <g stroke="#fff" stroke-width="2">
                <g transform="translate(4 4)">
                  <circle cx="7" cy="7" r="7" />
                  <line x1="16" x2="12.464" y1="16" y2="12.464" stroke-linecap="round" />
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span class="md:hidden">{{ $i18n('general.search') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    updateSearchQuery(e) {
      this.$store.commit('faq/updateSearchQuery', e.target.value);
      this.$store.dispatch('faq/makeSearch');
    },
    onSearch() {
      this.$store.dispatch('faq/makeSearch');
    },
  },
};
</script>

<style lang="scss">
.no-tap-highlight {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
