<template>
  <header class="bg-blue-600 relative leading-normal">
    <ReferralCashback />
    <div ref="highlightElement" class="bg-blue-600">
      <section
        class="md:container top-menu h-full md:h-auto fixed md:static md:py-7 md:px-10 bg-blue-600 md:bg-transparent w-full flex flex-col md:flex-row md:items-center z-30"
        :class="{
          'top-menu--collapse': collapse,
          'top-menu--scrolled': scrollTop,
        }"
      >
        <div
          class="header-logo-container w-full md:py-0 md:w-auto flex place-content-between px-3 md:px-0 transition-all duration-200"
          :class="{
            'py-1': scrollTop && collapse,
            'py-4': !collapse || !scrollTop,
          }"
        >
          <div class="flex items-center">
            <a href="/" class="top-menu__logo-wrapper p-3 md:-ml-3 -ml-4px" @click="clear">
              <Logo />
            </a>
            <PartnerLogo />
          </div>
          <div class="inline-flex items-center">
            <a href="/login/" @click="clear">
              <IconUser class="mr-3 md:hidden w-5 h-5" />
            </a>
            <ToggleMenu class="top-menu__toggle-menu md:hidden" :open="!collapse" @toggled="toggleMenu" />
          </div>
        </div>

        <nav
          class="text-xl md:text-base w-screen header-nav h-0 flex-1 flex flex-col md:static top-36 left-0 right-0 bg-white md:bg-transparent overscroll-contain"
          :class="{
            'header-nav--collapse': collapse,
            'header-nav--mega': megaMenuOpen,
          }"
        >
          <div
            ref="scroller"
            class="h-full md:overflow-y-visible w-full flex flex-col md:static md:flex-row md:items-center items-stretch overscroll-contain"
            :class="{
              'overflow-y-scroll': !isSearchActive && !languagesExpanded,
              relative: isSearchActive || languagesExpanded,
            }"
          >
            <ul
              class="header-nav__menu md:mx-auto flex md:flex-row flex-col min-h-max"
              :class="{ 'header-nav__menu--toggled': megaMenuOpen, 'hidden md:flex': languagesExpanded }"
            >
              <li class="header-nav__menu__item" :class="{ 'header-nav__menu__item--sub': megaMenuOpen }">
                <div class="text-black md:text-white w-full md:w-auto">
                  <LazyHydrate when-idle>
                    <MegaMenu :open="megaMenuOpen" :expression="searchExpression" @toggled="megaMenuToggled" />
                  </LazyHydrate>
                </div>
              </li>
              <li v-if="zenDeskLangCode" class="header-nav__menu__item">
                <a
                  class="header-nav__menu__item__link"
                  :href="`https://parkos.zendesk.com/hc/${zenDeskLangCode}`"
                  rel="nofollow"
                  @click="clear"
                >
                  <div class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2">
                    {{ $i18n('general.customer-service') }}
                  </div>
                  <IconCaretRight class="ml-auto text-black-alt-500 block md:hidden" />
                </a>
              </li>
              <li v-if="aboutPageLink" class="header-nav__menu__item">
                <a class="header-nav__menu__item__link" :href="aboutPageLink" @click="clear">
                  <div class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2">
                    {{ aboutPageTitle }}
                  </div>

                  <IconCaretRight class="ml-auto text-black-alt-500 block md:hidden" />
                </a>
              </li>
            </ul>
            <ul
              class="header-nav__menu header-nav__menu--abs flex md:flex-row flex-col"
              :class="{ 'header-nav__menu--sub': languagesExpanded, 'hidden md:flex': megaMenuOpen }"
            >
              <li class="header-nav__menu__item header-nav__menu__item--login">
                <a class="header-nav__menu__item__link" href="/login/" @click="clear">
                  <div class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2">
                    {{ $i18n('templates.header-login') }}
                  </div>
                  <IconCaretRight class="ml-auto text-black-alt-500 block md:hidden" />
                </a>
              </li>
              <li v-if="faqMeta" class="header-nav__menu__item pr-0 header-nav__menu__item--meta">
                <a class="header-nav__menu__item__link" :href="faqPageLink" @click="clear">
                  <div class="shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2">
                    {{ faqPageTitle }}
                  </div>
                  <IconCaretRight class="ml-auto text-black-alt-500 block md:hidden" />
                </a>
              </li>
              <li class="header-nav__menu__item pr-0" :class="{ 'header-nav__menu__item--toggled': languagesExpanded }">
                <MobileLanguages class="block md:hidden" :expanded="languagesExpanded" @toggled="languagesToggled" />
                <Languages class="font-sans hidden md:flex items-center" />
              </li>
            </ul>
          </div>
        </nav>
      </section>
    </div>
    <div class="xs:container pt-83px md:pt-12 pb-[60px] px-5 md:px-10 md:py-20 md:pb-40 relative sm:h-[466px]">
      <LazyHydrate when-idle when-visible>
        <CSAvatarParkos
          class="cs-avatar pointer-events-none max-w-3xl absolute right-[-8rem] top-[7rem] xxs:top-[8rem] sm:top-[4rem] md:max-w-[30rem] md:-top-12 md:-right-12 lg:max-w-3xl lg:-top-24 lg:-right-12"
        />
      </LazyHydrate>
      <div class="flex items-start flex-col relative w-[60%] md:w-[55%] text-white z-10">
        <h1
          class="text-[24px] md:text-5xl leading-normal airport-title-main flex mt-8 mb-3 md:my-[26px] font-cocogoose text-white relative"
        >
          {{ $i18n('templates.search-title') }}
        </h1>
        <p class="font-sans text-xl leading-relaxed">{{ $i18n('faq.welcome-to-the-parkos-help-center') }}</p>
      </div>
      <div class="flex flex-col md:block md:w-[45%]">
        <LazyHydrate when-idle when-visible>
          <Search ref="searchForm" class="mt-14 md:mt-7" />
        </LazyHydrate>
      </div>
    </div>
  </header>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import CSAvatarParkos from '~/components/header/CSAvatarParkos.vue';
import Languages from '~/components/header/Languages.vue';
import ToggleMenu from '~/components/header/ToggleMenu.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';
import IconUser from '~/components/icons/IconUser.vue';
import MobileLanguages from '~/components/header/MobileLanguages.vue';
import ReferralCashback from '~/components/ReferralCashback.vue';
import Logo from '../Logo.vue';
import PartnerLogo from './PartnerLogo.vue';
import MegaMenu from '~/components/header/MegaMenu.vue';

const Search = () => import('~/components/search/index.vue');

const BREAKPOINT_NAVIGATION = 990;
const { disableBodyScroll, clearAllBodyScrollLocks } = require('body-scroll-lock');

export default {
  components: {
    LazyHydrate,
    PartnerLogo,
    Logo,
    MegaMenu,
    CSAvatarParkos,
    Search,
    Languages,
    ToggleMenu,
    IconCaretRight,
    IconUser,
    MobileLanguages,
    ReferralCashback,
  },
  data() {
    return {
      collapse: true,
      scrollTop: 0,
      megaMenuOpen: false,
      searchExpression: '',
      languagesExpanded: false,
      showAirportsDropdown: true,
    };
  },
  computed: {
    airport() {
      return this.$store.state.airport;
    },
    language() {
      return this.$store.state.language;
    },
    zenDeskLangCode() {
      if (this.language && this.language.lang) {
        return this.language.zendesk_lang_code || this.language.lang;
      }
      return null;
    },
    aboutPageLink() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return `/${currentContent.slug}.html`;
      }
      return null;
    },
    aboutPageTitle() {
      if (this.aboutPageContent && this.language && this.language.lang) {
        const currentContent = this.aboutPageContent;
        return currentContent.title;
      }
      return null;
    },
    aboutPageContent() {
      return this.$store.state.pageContent.aboutPageContent;
    },
    metaPages() {
      return this.$store.state.metaPages;
    },
    faqMeta() {
      return this.metaPages.faqMeta;
    },
    faqPageLink() {
      return `//${this.$paths.host}/${this.faqMeta.slug}/`;
    },
    faqPageTitle() {
      return this.faqMeta.title;
    },
    isSearchActive() {
      return !!(this.searchExpression && this.searchExpression !== '');
    },
  },
  mounted() {
    document.addEventListener('scroll', this.scrollCheck);
  },
  methods: {
    scrollCheck() {
      this.scrollTop = window.scrollY;
    },
    toggleMenu(open) {
      this.collapse = !open;
      const { scroller } = this.$refs;
      if (this.collapse) {
        clearAllBodyScrollLocks();
        this.resetData();
      } else {
        disableBodyScroll(scroller);
      }
    },
    megaMenuToggled(show) {
      this.megaMenuOpen = show;
      if (show && window && window.outerWidth > BREAKPOINT_NAVIGATION) {
        this.$refs.highlightElement.skipScrollLock = window && window.outerWidth > BREAKPOINT_NAVIGATION;
        this.$store.commit('highlight/show', this.$refs.highlightElement);
      } else if (window && window.outerWidth > BREAKPOINT_NAVIGATION) {
        this.$store.dispatch('highlight/hide');
      }
    },
    clear() {
      this.toggleMenu();
    },
    languagesToggled(expanded) {
      this.languagesExpanded = expanded;
      disableBodyScroll(this.$refs.scroller);
    },
    resetData() {
      this.searchExpression = '';
      this.megaMenuOpen = false;
      this.languagesExpanded = false;
      this.$store.dispatch('highlight/hide');
    },
    changeAirport() {
      this.showAirportsDropdown = true;
      this.$nextTick(() => {
        this.$refs.searchForm.handleAirportToggle();
      });
    },
  },
};
</script>

<style lang="scss">
.top-menu {
  $self: &;
  &--collapse {
    @apply place-content-between h-auto;
  }

  &__search {
    @apply block md:hidden;
  }
}

.header-nav {
  $self: &;
  a {
    &:hover {
      @media (min-width: 1080px) {
        @apply underline;
      }
    }
  }
  &--collapse {
    @apply hidden md:flex h-auto;
  }

  &--mega {
    @apply top-18;
  }

  @apply md:h-auto;
  &__menu {
    $self: &;

    &--toggled {
      #{$self}__item {
        @apply hidden md:flex;
        &--sub {
          @apply flex;
        }
      }
    }

    &__item {
      @apply flex flex-1 border-b border-athens md:border-none select-none w-full xs:w-auto;
      min-height: max-content;
      &--login {
        @apply hidden md:flex;
      }
      &--meta {
        @apply md:hidden;
      }
      &__link {
        @apply py-6 place-content-between items-center w-full px-6 md:w-auto md:px-3 md:py-2 text-black md:text-white font-sans flex justify-between cursor-pointer;
        &:hover {
          @apply no-underline;
        }
      }
    }
  }
}
.icon-checkmark {
  @apply text-blue-alt mr-2 md:mr-3 md:scale-150 lg:scale-100 transform;
}
</style>
