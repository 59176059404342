<template>
  <div
    class="inline-flex place-content-between flex-col cursor-pointer h-4 p-3 box-content"
    :class="{ collapse: !open }"
    @click.stop.prevent="toggle"
  >
    <div class="sr-only">Toggle Navigation</div>
    <div
      class="w-5 h-0.5 bg-white rounded transition-transform duration-200 transform origin-left"
      :class="{ 'rotate-45': open }"
    />
    <div class="w-4 h-0.5 bg-white rounded transition-opacity duration-150 transform" :class="{ 'opacity-0': open }" />
    <div
      class="w-5 h-0.5 bg-white rounded transition-transform duration-200 transform origin-left"
      :class="{ '-rotate-45': open }"
    />
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    toggle() {
      this.$emit('toggled', !this.open);
    },
  },
};
</script>

<style></style>
