<template>
  <section id="support" class="support">
    <h3 class="support__title">
      {{ $i18n('general.right-answer') }}
    </h3>
    <p class="support__lead">
      {{ $i18n('general.contact-with-employees') }}
    </p>

    <section class="support-options">
      <div class="support-option">
        <div class="support-option__header">
          <h4>{{ $i18n('customer.call-us') }}</h4>
          <div class="support-option__status" :class="getStatusClass">
            {{ getStatusText }}
          </div>
        </div>
        <div class="support-option__content">
          <p href="#" class="support-option__phone">
            <img src="~/static/general/phone-icon.svg" class="w-4 h-4" aria-hidden="true" />
            <a href="#">{{ $i18n('faq.call-us-phone-number') }}</a>
          </p>
        </div>
        <button class="support-option__link" @click="openModal">
          {{ $i18n('faq.faq-see-opening-time') }}
        </button>
      </div>
      <div class="support-option">
        <div class="support-option__header">
          <h4>{{ $i18n('customer.mail-us') }}</h4>
        </div>
        <div class="support-option__content">
          <p>{{ $i18n('general.response-hour') }}</p>
        </div>
        <a :href="`mailto:${$i18n('faq.mail-us-address')}`" class="support-option__link">
          {{ $i18n('faq.mail-us-address') }}
        </a>
      </div>
    </section>
    <OpeningTimes v-model="modal" />
  </section>
</template>

<script>
import OpeningTimes from '~/components/OpeningTime.vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export default {
  name: 'Support',
  components: {
    OpeningTimes,
  },
  data() {
    return {
      openOffice: true,
      modal: false,
    };
  },
  computed: {
    getStatusClass() {
      return this.phonelinesOpen() ? '--state-open' : '--state-closed';
    },
    getStatusText() {
      return this.phonelinesOpen() ? this.$i18n('general.now-open') : this.$i18n('general.closed');
    },
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    phonelinesOpen() {
      const now = dayjs.tz(dayjs(), 'Europe/Amsterdam');
      const day = now.get('day');

      const { openingTimes, language } = this.$store.state;
      const { openingHours } = openingTimes[language.lang];
      const { startTime, endTime } = openingHours.find((o) => o.dayOfWeek === day);

      const parsedStartTime = startTime.split(':');
      const parsedEndTime = endTime.split(':');

      const startHour = parseInt(parsedStartTime[0], 10);
      const startMinute = parseInt(parsedStartTime[1], 10);

      const endHour = parseInt(parsedEndTime[0], 10);
      const endMinute = parseInt(parsedEndTime[1], 10);

      const start = now.clone().hour(startHour).minute(startMinute).second(0);
      const end = now.clone().hour(endHour).minute(endMinute).second(0);

      if (start.isSameOrBefore(end)) {
        return now.isSameOrAfter(start) && now.isSameOrBefore(end);
      }

      // Handle case where the time range spans across two different days
      return now.isSameOrAfter(start) || now.isSameOrBefore(end);
    },
  },
};
</script>

<style lang="scss">
.--state-open {
  @apply text-[#20a200];
}

.--state-closed {
  @apply text-[#d92001];
}

.support {
  @apply w-full py-10 md:py-20;

  &__title {
    @apply mb-2;
    @apply text-xl md:text-2xl font-cocogoose;
  }

  &__lead {
    @apply text-md md:text-base;
  }
}

.support-options {
  @apply flex flex-col md:flex-row w-full gap-4 md:gap-8;
  @apply pt-8;
}

.support-option {
  @apply w-full border border-athens rounded bg-white p-5 md:p-7;

  &__header {
    @apply flex justify-between items-center mb-2;

    h4 {
      @apply text-base md:text-xl font-cocogoose;
    }
  }

  &__status {
    @apply flex items-center;
    @apply text-base;

    &:before {
      width: 6px;
      height: 6px;
      @apply block rounded-full bg-current mr-3;
      content: '';
    }
  }

  &__phone {
    @apply flex items-center gap-2;
    @apply text-base md:text-xl font-bold;
  }

  &__content {
    @apply mb-2;
  }

  &__link {
    @apply text-base text-blue-600;
  }
}
</style>
