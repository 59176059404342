<template>
  <div class="sticky top-[20px]">
    <div class="text-xl mb-[18px] font-bold">
      {{ $i18n('faq.topics') }}
    </div>
    <div v-if="topics && topics.length">
      <button
        v-for="(topic, index) in topics"
        :key="topic.slug"
        class="topic"
        :class="[
          { '--state-selected': (selectedTopic && selectedTopic.id == topic.id) || (!selectedTopic && index == 0) },
        ]"
        @click="selectTopic(topic)"
      >
        {{ topic.name }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    selectedTopic() {
      return this.$store.state.faq.selectedTopic;
    },
    topics() {
      return this.$store.state.faq.topics;
    },
  },
  methods: {
    selectTopic(topic) {
      this.$store.commit('faq/setSelectedTopic', topic);
    },
  },
};
</script>

<style lang="scss" scoped>
.topic {
  @apply w-full rounded hover:bg-blue-50 mb-2 text-left text-blue-600 p-2 break-words leading-5;

  &.--state-selected {
    @apply bg-blue-100 hover:bg-blue-100 font-bold;
  }

  &:not(:last-child) {
    @apply mb-2;
  }
}
</style>
