<template>
  <Dropdown key="languages-dropdown" class="border-b-0 pr-0 -mr-6" @toggled="handleToggle">
    <template #button>
      <div class="flex items-center sm:w-auto w-full">
        <Flag :lang="language.lang" />
        {{ language.lang.split('-')[0] | uppercase }}
        <span class="caret hidden sm:block ml-1" />
        <IconCaretRight class="text-black-alt-500 ml-auto sm:hidden" />
      </div>
    </template>
    <template #content>
      <ul class="sm:py-2 sm:-ml-2">
        <li v-for="item in languages" v-show="item.lang !== language.lang" :key="`lang-${item.lang}`">
          <a :href="`//${item.domain}/`" class="language-link">
            <Flag :lang="item.lang" loading="lazy" />
            {{ item.native_name }}
          </a>
        </li>
      </ul>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '~/components/header/Dropdown.vue';
import Flag from '~/components/icons/Flag.vue';
import IconCaretRight from '~/components/icons/IconCaretRight.vue';

export default {
  filters: {
    uppercase: (value) => value.toUpperCase(),
  },

  components: {
    Dropdown,
    Flag,
    IconCaretRight,
  },

  computed: {
    languages() {
      return this.$store.state.languages
        .filter((lang) => !lang.is_hidden)
        .sort((a, b) => (a.native_name > b.native_name ? 1 : -1));
    },
    language() {
      return this.$store.state.language;
    },
  },
  methods: {
    handleToggle(expanded) {
      this.$emit('toggled', expanded);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-link {
  @apply block sm:border-none border-b border-athens sm:py-1 sm:hover:bg-gray-200 flex items-center;
  @apply px-6 sm:px-4 py-1 text-base text-gray-600 whitespace-nowrap sm:text-black hover:text-black hover:no-underline;
}
</style>
