<template>
  <details v-if="content" name="faq" class="faq-card">
    <summary class="faq-card__wrapper">
      <h3 class="faq-card__question">
        {{ question }}
      </h3>
      <img class="faq-card__icon" :src="`${$paths.assetsUrl}img/static-pages/chevron-down.svg`" alt="chevron" />
    </summary>
    <div>
      <div class="flex flex-col">
        <div class="overflow-hidden w-full pb-4" v-html="shortAnswer" />
      </div>
    </div>
    <client-only>
      <noscript>
        <div class="flex flex-col">
          <div class="overflow-hidden w-full pb-4" v-html="shortAnswer" />
        </div>
      </noscript>
    </client-only>
  </details>
</template>

<script>
import { parse } from 'node-html-parser';

export default {
  props: {
    content: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    question() {
      return this.content.title;
    },
    shortAnswer() {
      const root = parse(this.content.body);
      const links = root.querySelectorAll('a');

      if (links && links.length) {
        links.forEach((link) => {
          const href = link.getAttribute('href');

          if (href && href.includes('parkos.zendesk.com')) {
            let relAttributes = link.getAttribute('rel');

            if (!relAttributes) {
              relAttributes = 'nofollow';
            } else {
              relAttributes = `${relAttributes} nofollow`;
            }

            link.setAttribute('rel', relAttributes);
          }
        });
      }

      return root.toString();
    },
  },
};
</script>

<style lang="scss">
.faq-card {
  @apply self-start flex flex-col border-t border-athens font-sans;

  &__wrapper {
    @apply flex justify-between items-center cursor-pointer py-1;
  }

  &__question {
    @apply select-none m-0 mr-5 flex items-center font-bold;
  }

  &__icon {
    @apply w-6 h-6 relative transition-all transform;

    &__button-wrapper {
      @apply flex items-center;
    }
  }
}

.faq-card {
  &__question {
    min-height: calc(3.5rem);
  }

  a {
    @apply text-blue-500 hover:underline;
  }

  ul {
    @apply pl-[20px] m-[20px] mr-0 list-disc list-outside;
  }

  p {
    @apply my-2;
  }

  .link-button {
    @apply m-[20px];

    a {
      @apply w-full block p-3 rounded bg-orange-500 hover:bg-orange-600 text-white font-semibold hover:no-underline text-center;
    }
  }
}

[open] {
  .faq-card {
    &__question {
      @apply font-bold;
    }

    &__icon {
      @apply rotate-180;
    }
  }
}
</style>
